import React from 'react';
import styled from 'styled-components';

const FooterContainer = styled.footer`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  background-color: ${(props) => props.theme.primaryBackgroundColor};
`;

const FooterText = styled.p`
  font-size: 0.7rem;
  margin: auto;
  color: #999;
`;

const Footer = () => {
  return (
    <FooterContainer>
      <FooterText>David Kurniawan © 2021</FooterText>
    </FooterContainer>
  );
};

export default Footer;
