import styled from 'styled-components';

export const CardContainer = styled.div`
  padding: 1.2rem;
  background-image: ${props => props.theme.secondaryBackgroundColor};
  max-height: 27rem;
  height: 100%;
  border-radius: 10px;
  margin: 0.5rem 0 2rem 0;
  box-shadow: 1px 2px 2px 0px ${props => props.theme.shadowColor};
  transition: transform 0.3s;
  font-size: 0.9rem;

  &:hover {
    transform: translate(-6px, -10px);
    box-shadow: 8px 8px 8px 0px ${props => props.theme.shadowColor};
  }
  display: flex;
  flex-direction: column;
`;

export const CardTitle = styled.h4`
  color: ${props => props.theme.titleColor};
  height: 10%;
  font-size: 105%;
`;

export const CardDescription = styled.p`
  height: 60%;
`;

export const CardTechContainer = styled.div`
  color: ${props => props.theme.techColor};
  height: 30%;
`;

export const CardTechText = styled.p`
  marginbottom: 0.2rem;
`;

export const CardLinksContainer = styled.div`
  height: 5%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 1rem;
`;
